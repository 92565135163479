<template>
  <div class="category-page__filters">
    <SfAccordion :multiple="true" :open="openAccordions">
      <SfAccordionItem
        v-for="(filter, filterIndex) in selectableAvailableFilters"
        :key="`category-filters-${filter.label}-${filterIndex}`"
        :header="filterLabelsMap[filter.label] || filter.label"
      >
        <CategoryFilterRow
          v-for="(option, optionIndex) in filter.options"
          :key="`category-filters-${categoryId}-${optionIndex}-${option.value}-checkbox-${randomKey}`"
          :label="`${option.label} (${option.count})`"
          :selected="option.active"
          @click.native="handleAddOrRemoveFilter(filter.label, option.value)"
        />
      </SfAccordionItem>
    </SfAccordion>
    <div class="category-page__filters__ctas">
      <CustomButton @click="handleApplyFilters" specific-width="100%">
        APPLICA FILTRI
      </CustomButton>
      <CustomButton
        @click="resetFilters"
        theme="white"
        specific-width="100%"
        :disabled="disableResetFilters"
      >
        AZZERA FILTRI
      </CustomButton>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  shallowRef,
  ref,
  onMounted,
  computed,
} from '@nuxtjs/composition-api';
import { useCategoryPage, useUiState } from '~/composables';
import { CustomButton } from '~/components/General/FormElements';
import { SfAccordion } from '@storefront-ui/vue';
import CategoryFilterRow from '~/components/Category/CategoryFilterRow.vue';
import dataManipulation from '~/helpers/dataManipulation';
import { scrollToTop } from '~/composables/useCategoryPage/_helpers';

export default defineComponent({
  name: 'CategoryFilters',
  components: { CustomButton, SfAccordion, CategoryFilterRow },
  props: {
    categoryId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const {
      selectableAvailableFilters,
      currentFilters,
      addOrRemoveFilter,
      loadProducts,
      filterLabelsMap,
      resetFilters: resetFiltersComposable,
    } = useCategoryPage(props.categoryId);
    const { generateUniqueKey } = dataManipulation();
    const randomKey = shallowRef(generateUniqueKey());
    const openAccordions = ref('');
    const { isFilterSidebarOpen, toggleFilterSidebar } = useUiState();

    const disableResetFilters = computed(
      () =>
        Object.keys(currentFilters.value.filters).length === 0 ||
        Object.values(currentFilters.value.filters).every((v) => v.length === 0)
    );

    const handleApplyFilters = async () => {
      if (isFilterSidebarOpen.value) toggleFilterSidebar(props.categoryId);
      scrollToTop();
      await loadProducts();
    };

    const handleAddOrRemoveFilter = (filter, value) => {
      addOrRemoveFilter(filter, value);
    };

    const resetFilters = async () => {
      await resetFiltersComposable();
    };

    onMounted(() => {
      openAccordions.value =
        selectableAvailableFilters.value
          ?.map((filter) =>
            filter.options.find((o) => o.active)
              ? filterLabelsMap[filter.label] || filter.label
              : false
          )
          .filter(Boolean) || '';
    });

    return {
      selectableAvailableFilters,
      currentFilters,
      filterLabelsMap,
      handleAddOrRemoveFilter,
      handleApplyFilters,
      randomKey,
      openAccordions,
      resetFilters,
      disableResetFilters,
    };
  },
});
</script>

<style lang="scss">
.category-page__filters {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: calc(100vh - 12.6875rem);
  overflow-y: auto;
  .sf-accordion-item__header {
    width: 100% !important;
    justify-content: space-between !important;
    border-bottom: 1px solid var(--c-black) !important;
    padding: 1rem 0 0.75rem 0 !important;
  }
  &__ctas {
    display: none;
  }
  .sf-accordion-item {
    &__header.is-open {
      --chevron-color: var(--c-black) !important;
    }
    &__content {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .sf-chevron__bar:after {
    background: var(--c-black) !important;
    background-color: var(--c-black) !important;
  }
}
@include from-desktop-min {
  .category-page__filters {
    margin-top: 3rem;
    height: auto;
    padding-bottom: 0;
    &__ctas {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}
</style>
