<template>
  <div class="checkout-header">
    <div class="checkout-header__content">
      <a class="checkout-header__content__back" href="/it">
        <ArrowLeftIcon class="checkout-header__content__back__icon" />
      </a>
      <a class="checkout-header__content__logo" href="/it">
        <ColfertLogo class="checkout-header__content__logo__icon" />
      </a>
      <span class="checkout-header__content__hidden">HIDDEN</span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { ArrowLeftIcon, ColfertLogo } from '~/components/General/Icons';

export default defineComponent({
  name: 'CheckoutHeader',
  components: { ArrowLeftIcon, ColfertLogo },
  props: {},
  setup() {},
});
</script>

<style lang="scss" scoped>
.checkout-header {
  background-color: var(--c-primary);
  height: 7vh;
  &__content {
    @include paragraph-regular;
    display: flex;
    align-items: center;
    width: 100vw;
    margin: 0 auto;
    height: 100%;

    &__back {
      @include pointer;
      &__icon {
        transform: scale(0.2);
      }
    }

    &__logo {
      &__icon {
        transform: scale(0.3);
      }
    }

    &__hidden {
      visibility: hidden;
    }
  }
}
@include to-tablet-max {
  .checkout-header {
    &__content {
      &__logo,
      &__hidden,
      &__back {
        width: 6.25rem;
      }
    }
  }
}

@include from-desktop-min {
  .checkout-header {
    height: 5vh;
    &__content {
      width: 1440px;
      justify-content: space-between;
    }
  }
}
</style>
